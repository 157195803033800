import styled from '@emotion/styled'

import {
  TABLET_MIN_BREAKPOINT,
  DESKTOP_MIN_BREAKPOINT,
  WIDESCREEN_MIN_BREAKPOINT,
  FULLHD_MIN_BREAKPOINT,
} from '../constants/breakpoints'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`
const Brick = styled.figure`
  flex: auto;
  min-width: 5rem;
  height: auto;
  margin: 0 0.5rem 0.5rem 0;

  &:hover {
    cursor: pointer;
  }

  &:nth-child(4n + 1) {
    width: 7rem;
  }
  &:nth-child(4n + 2) {
    width: 8rem;
  }
  &:nth-child(4n + 3) {
    width: 6rem;
  }
  &:nth-child(4n + 4) {
    width: 9rem;
  }
  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    min-width: 6rem;
    margin: 0 1rem 1rem 0;

    &:nth-child(4n + 1) {
      width: 11rem;
    }
    &:nth-child(4n + 2) {
      width: 13rem;
    }
    &:nth-child(4n + 3) {
      width: 8rem;
    }
    &:nth-child(4n + 4) {
      width: 15rem;
    }
  }
  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    min-width: 8rem;

    &:nth-child(4n + 1) {
      width: 14rem;
    }
    &:nth-child(4n + 2) {
      width: 17rem;
    }
    &:nth-child(4n + 3) {
      width: 10rem;
    }
    &:nth-child(4n + 4) {
      width: 18rem;
    }
  }
  @media screen and (${WIDESCREEN_MIN_BREAKPOINT}) {
    min-width: 10rem;
    margin: 0 1.5rem 1.5rem 0;

    &:nth-child(4n + 1) {
      width: 18rem;
    }
    &:nth-child(4n + 2) {
      width: 20rem;
    }
    &:nth-child(4n + 3) {
      width: 14rem;
    }
    &:nth-child(4n + 4) {
      width: 22rem;
    }
  }
  @media screen and (${FULLHD_MIN_BREAKPOINT}) {
    min-width: 10rem;
    margin: 0 1.5rem 1.5rem 0;

    &:nth-child(4n + 1) {
      width: 20rem;
    }
    &:nth-child(4n + 2) {
      width: 22rem;
    }
    &:nth-child(4n + 3) {
      width: 16rem;
    }
    &:nth-child(4n + 4) {
      width: 24rem;
    }
  }
`

export default { Container, Brick }
