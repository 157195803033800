import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, withPrefix } from 'gatsby'
import Layout from '../components/Layout'
import { Grid, Row, Col } from 'react-flexbox-grid'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { useIntl } from 'gatsby-plugin-intl'

import ResponsiveTitle from '../components/ResponsiveTitle'
import Lightbox from '../components/Lightbox'
import BaseButton from '../components/BaseButton'
import MasonryGrid from '../components/MasonryGrid'
import { shuffle } from '../util'
import { TABLET_MIN_BREAKPOINT } from '../constants/breakpoints'

const BackButtonContainer = styled.div`
  padding: 0 1.5rem 2rem 1.5rem;

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    padding: 0;
  }
`

const BackButton = styled(BaseButton)`
  ::before {
    content: '←';
    padding: 0 0.5rem 0 0;
  }
`

const TitleContainer = styled.div`
  padding-bottom: ${props => (props.hasMultipleImageTypes ? '0' : '4rem')};
`

const TabsContainer = styled.div`
  padding: 1rem 0;
`

const ThumbnailImg = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const TabItem = ({ isActive, onClick, children }) => (
  <li className={isActive ? 'is-active' : ''}>
    <a href="#" onClick={onClick}>
      {children}
    </a>
  </li>
)

export const PortfolioCategoryPageTemplate = ({
  categoryKey,
  hasMultipleImageTypes,
  thumbnailsBw,
  thumbnailsColour,
}) => {
  const thumbnailsAll = useMemo(
    () =>
      hasMultipleImageTypes ? shuffle([...thumbnailsColour, ...thumbnailsBw], 3) : thumbnailsColour,
    [hasMultipleImageTypes, thumbnailsBw, thumbnailsColour]
  )
  const [activeThumbnails, setActiveThumbnails] = useState(thumbnailsAll)
  const [hasActiveLightbox, setHasActiveLightbox] = useState(false)
  const [lightboxImageIndex, setLightboxImageIndex] = useState(0)
  const { formatMessage } = useIntl()

  return (
    <Grid fluid>
      <Row>
        <BackButtonContainer>
          <Link to={`${withPrefix('/')}portfolio`}>
            <BackButton className="button">
              {formatMessage({ id: 'portfolio.overview' })}
            </BackButton>
          </Link>
        </BackButtonContainer>
      </Row>
      <Row>
        <Col xs={12}>
          <TitleContainer hasMultipleImageTypes={hasMultipleImageTypes}>
            <ResponsiveTitle title={formatMessage({ id: `portfolio.${categoryKey}` })} />
          </TitleContainer>
        </Col>
      </Row>
      {hasMultipleImageTypes && (
        <Row>
          <TabsContainer className="tabs">
            <ul>
              <TabItem
                isActive={activeThumbnails === thumbnailsAll}
                onClick={() => setActiveThumbnails(thumbnailsAll)}
              >
                {formatMessage({ id: 'portfolio.tabs.all' })}
              </TabItem>
              <TabItem
                isActive={activeThumbnails === thumbnailsColour}
                onClick={() => setActiveThumbnails(thumbnailsColour)}
              >
                {formatMessage({ id: 'portfolio.tabs.colour' })}
              </TabItem>
              <TabItem
                isActive={activeThumbnails === thumbnailsBw}
                onClick={() => setActiveThumbnails(thumbnailsBw)}
              >
                {formatMessage({ id: 'portfolio.tabs.bw' })}
              </TabItem>
            </ul>
          </TabsContainer>
        </Row>
      )}
      <Row>
        <MasonryGrid.Container>
          {activeThumbnails.map((imageInfo, i) => {
            return (
              <MasonryGrid.Brick
                key={i}
                onClick={() => {
                  setLightboxImageIndex(i)
                  setHasActiveLightbox(true)
                }}
              >
                <LazyLoadComponent threshold={0}>
                  <ThumbnailImg fluid={imageInfo.image.childImageSharp.fluid} />
                </LazyLoadComponent>
              </MasonryGrid.Brick>
            )
          })}
        </MasonryGrid.Container>
      </Row>
      {hasActiveLightbox && (
        <Lightbox
          gatsbyImages={activeThumbnails}
          selectedImageIndex={lightboxImageIndex}
          onCloseRequest={() => setHasActiveLightbox(false)}
        />
      )}
    </Grid>
  )
}

PortfolioCategoryPageTemplate.propTypes = {
  categoryKey: PropTypes.string.isRequired,
  hasMultipleImageTypes: PropTypes.bool.isRequired,
  thumbnailsBw: PropTypes.array,
  thumbnailsColour: PropTypes.array,
}

const PortfolioCategoryPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data
  const { imageTypes } = frontmatter

  return (
    <Layout>
      <PortfolioCategoryPageTemplate
        categoryKey={frontmatter.categoryKey}
        thumbnailsBw={imageTypes.bw?.images}
        thumbnailsColour={imageTypes.colour?.images}
        hasMultipleImageTypes={!!imageTypes.bw && !!imageTypes.colour}
      />
    </Layout>
  )
}

PortfolioCategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PortfolioCategoryPage

export const portfolioCategoryPageQuery = graphql`
  query PortfolioCategoryPage($categoryKey: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "portfolio-category-page" }
        categoryKey: { eq: $categoryKey }
      }
    ) {
      frontmatter {
        categoryKey
        imageTypes {
          bw {
            images {
              image {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 92) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          colour {
            images {
              image {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 92) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
